import axios from 'axios';

import requestInterceptor from './requestInterceptor';
import responseInterceptor from './responseInterceptor';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://tangi.test';

export const API_URL = API_BASE_URL + '/api/v1';
export const Backend_URL = API_BASE_URL + '/storage/';


const httpClient = axios.create({
  baseURL: API_URL,
});
requestInterceptor(httpClient);
responseInterceptor(httpClient);

export default httpClient;
