import {lazy, FC, Suspense} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';

import {MasterLayout} from '_metronic/layout/MasterLayout';
import {getCSSVariableValue} from '_metronic/assets/ts/_utils';
import {WithChildren} from '_metronic/helpers';
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper';
import {MenuTestPage} from '../pages/MenuTestPage';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';

const PrivateRoutes = () => {
  const QuestionBank = lazy(() => import('../modules/question-bank/QuestionBank'));
  const MediaLessons = lazy(() => import('../modules/media-lessons/MediaLessons'));
  const Mindmaps = lazy(() => import('../modules/mindmaps'));
  const Contents = lazy(() => import('../modules/contents/Contents'));
  const Objects = lazy(() => import('../modules/objects'));
  const Shop = lazy(() => import('../modules/shop'));
  const FAQs = lazy(() => import('../modules/faqs'));
  const About = lazy(() => import('../modules/about'));
  const Admins = lazy(() => import('../modules/admins'));
  const Users = lazy(() => import('../modules/users'));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='question-bank/*'
          element={
            <SuspensedView>
              <QuestionBank />
            </SuspensedView>
          }
        />
        <Route
          path='media-lessons/*'
          element={
            <SuspensedView>
              <MediaLessons />
            </SuspensedView>
          }
        />
        <Route
          path='mindmaps/*'
          element={
            <SuspensedView>
              <Mindmaps />
            </SuspensedView>
          }
        />
        <Route
          path='contents/*'
          element={
            <SuspensedView>
              <Contents />
            </SuspensedView>
          }
        />
        <Route
          path='objects/*'
          element={
            <SuspensedView>
              <Objects />
            </SuspensedView>
          }
        />
        <Route
          path='shop/*'
          element={
            <SuspensedView>
              <Shop />
            </SuspensedView>
          }
        />
        <Route
          path='faqs/*'
          element={
            <SuspensedView>
              <FAQs />
            </SuspensedView>
          }
        />
        <Route
          path='about-us/*'
          element={
            <SuspensedView>
              <About />
            </SuspensedView>
          }
        />
        <Route
          path='admins/*'
          element={
            <SuspensedView>
              <Admins />
            </SuspensedView>
          }
        />
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <Users />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export {PrivateRoutes};
