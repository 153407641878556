import {FC} from 'react';
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom';
import {PrivateRoutes} from './PrivateRoutes';
import {ErrorsPage} from '../modules/errors/ErrorsPage';
import {Logout, AuthPage, useAuth} from '../modules/auth';
import {App} from '../App';
// import { usePageTracking } from '_metronic/gtag/usePageTracking';
const {PUBLIC_URL} = process.env;

const AppRoutes: FC = () => {
  const {currentUser} = useAuth();
  /*
  gtag: https://javascript.plainenglish.io/the-ultimate-guide-to-google-analytics-ua-ga4-on-react-or-anything-else-ec9dc84c0211
  */ 
  // usePageTracking();
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export {AppRoutes};
