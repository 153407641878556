import {createRoot} from 'react-dom/client';
import {Chart, registerables} from 'chart.js';
import {QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {MetronicI18nProvider} from '_metronic/i18n/Metronici18n';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import '_metronic/assets/css/style.rtl.css'
 **/
import '_metronic/assets/sass/style.scss';
import '_metronic/assets/sass/plugins.scss';
import '_metronic/assets/sass/style.react.scss';
import {AppRoutes} from './app/routing/AppRoutes';
import {queryClient} from './app/react-query/queryClient';
import {AuthProvider} from './app/modules/auth';
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
Chart.register(...registerables);

const container = document.getElementById('root');
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
        <ToastContainer position='bottom-center' />
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
