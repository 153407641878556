import httpClient from '_metronic/api/httpclient';
import {AuthModel} from './_models';

// export const GET_USER_BY_ACCESSTOKEN_URL = `/verify_token`;
export const LOGIN_URL = '/admin/admin/login';
export const REFRESH_URL = '/admin/admin/refresh';
// export const REGISTER_URL = `/register`;
// export const REQUEST_PASSWORD_URL = `/forgot_password`;

export function login(email: string, password: string) {
  return httpClient.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

export function refresh(refresh_token: string) {
  return httpClient.post<AuthModel>(REFRESH_URL, {
    refresh_token,
  });
}

// export function register(
//   email: string,
//   firstname: string,
//   lastname: string,
//   password: string,
//   password_confirmation: string
// ) {
//   return httpClient.post(REGISTER_URL, {
//     email,
//     first_name: firstname,
//     last_name: lastname,
//     password,
//     password_confirmation,
//   });
// }

// Server should return object => { result: boolean } (Is Email in DB)
// export function requestPassword(email: string) {
//   return httpClient.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
//     email,
//   });
// }
