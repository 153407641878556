import {toast} from 'react-toastify';
import {getAuth, removeAuth, setAuth} from '../../app/modules/auth';
import {refresh} from '../../app/modules/auth/core/_requests';

// const DEBUG = process.env.REACT_APP_NODE_ENV !== 'production';

export default async function responseInterceptor(axios: any) {
  axios.interceptors.response.use(
    (response: any) => {
      return response;
    },
    async (error: any) => {
      const err = JSON.parse(JSON.stringify(error));

      if (err.status === 401) {
        try {
          const user = getAuth();
          const refreshToken = user?.result.refresh.token;
          if (!refreshToken) {
            window.location.href = '/auth';
            return;
          }
          removeAuth();
          await refreshHandler(refreshToken);
        } catch (error) {
          window.location.href = '/auth';
        }
      }

      if (err.status === 404) {
        toast.error('Wrong Request!');
      } else if (err.status === 500) {
        toast.error('Server error!');
      } else if (err.status === 422) {
        toast.error('Wrong form data!');
      } else {
        console.log(err.message);
        console.log(err.status);
      }
    }
  );
}

const refreshHandler = async (refreshToken: string) => {
  try {
    const {data} = await refresh(refreshToken);
    setAuth(data);
  } catch (error) {
    removeAuth();
  }
};
