import {getAuth} from '../../app/modules/auth';

export default function requestInterceptor(axios: any) {
  axios.defaults.headers.Accept = 'application/json';
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth();
      if (auth && auth.result && auth.result.access.token) {
        config.headers.Authorization = `Bearer ${auth.result.access.token}`;
      }

      return config;
    },
    (err: any) => {
      Promise.reject(err);
    }
  );
}
