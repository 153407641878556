import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from './core/Auth';

export function Logout() {
  const navigate = useNavigate();
  const {logout} = useAuth();
  useEffect(() => {
    logout();
    navigate('/aut/login');
  }, [logout, navigate]);

  return null;
}
