import {QueryClient} from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // onError: queryErrorHandler,
      staleTime: 10 * 1000 * 60, // 10 min
      cacheTime: 15 * 1000 * 60, // 15 min
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      // onError: queryErrorHandler
    },
  },
});
